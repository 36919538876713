.App {
  text-align: center;
  background-color: #1e1e1e; /* Keeping the darker background */
  color: #fff; /* White text for better contrast */
}

audio {
  margin-top: 20px;
  width: 90%; /* Adjust if necessary to fit your design */
}

p {
  color: #adb5bd; /* Light grey for paragraphs */
  /* border: 2px solid #4CAF50;  Green border for the transcription field */
  padding: 5px; /* Padding inside the border */
  margin: 10px auto; /* Centered with some margin */
  width: 80%; /* Adjust width as needed */
  min-height: 30px; /* Ensure there's space for text before transcription is added */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Talk section with Specific button styles */

/* Base button styles, simplified */
button {
  border: none; /* Removes the border */
  cursor: pointer; /* The cursor will change to a pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for visual feedback */
  font-size: 16px; /* Standard readable font size */
}

/* Specific styles for the record button */
.record-btn {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 200px; /* Adjust to the size of your image */
  height: 200px; /* Adjust to the size of your image */
  border-radius: 50%; /* This makes the button round */
  overflow: hidden; /* Prevents anything from spilling outside the button's round shape */
  display: flex;
  justify-content: center;
  align-items: center;
}
  .record-btn img {
    margin: 0;
    padding: 0;
    /* Set a specific size for the image if needed */
    width: 100%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
  }

/* Styles for the 'Send to Diary' button */
.send-btn {
  background-color: #4AA4D8; /* Background color for the button */
  color: black; /* Text color */
  padding: 10px 20px; /* Padding to make the button larger */
  margin-top: 20px; /* Space above the button */
  border-radius: 5px; /* Rounded corners */
}

/* Styles for when the record button is disabled */
button:disabled {
  background-color: #cccccc; /* Gray out the button */
  cursor: not-allowed; /* Change the cursor to indicate the action is not allowed */
}

/* Container styles */
.recorder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Takes the full height of the viewport */
}

.button-container {
  margin-top: 20px; /* Adds space between buttons if they are stacked */
}

/* Transcription container styles */
.transcription-container {
  text-align: center;
  margin-top: 20px; /* Adds space below the transcription */
}

/* Instruction text styles */
.instruction-text {
  margin-bottom: 20px; /* Space between the instruction text and the button */
  font-size: 18px; /* Size of the instruction text */
}

/* Animation for the recording state */
.recording {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(76, 175, 80, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(76, 175, 80, 0); /* Increase the size of the shadow for larger animation effect */
  }
  100% {
    box-shadow: 0 0 0 0px rgba(76, 175, 80, 0);
  }
}

/* Navbar */

nav {
  background-color: #333; /* Slightly lighter than the app background for contrast */
  padding: 20px 0; /* Add some vertical padding */
  display: flex;
  justify-content: center; /* Center the nav items if not many */
  align-items: center;
}

nav a {
  color: #fff; /* White text */
  text-decoration: none; /* No underlines */
  margin: 0 15px; /* Space out links */
  font-size: 18px; /* Larger text for easy clicking/tapping */
  transition: color 0.3s; /* Smooth transition for hover effects */
}

nav a:hover {
  color: #4CAF50; /* Highlight link on hover */
}


/* Public diary page */

.diary-entry {
  background-color: #fff; /* or any other color you prefer */
  color: #333; /* or any other color you prefer */
  border-radius: 8px; /* Rounded corners */
  border: 2px solid #4CAF50;
  margin: 50px;
  padding: 10px; /* Padding inside the entry */

  justify-content: center;
  align-items: center;
  text-align: center;
}

.diary-entry p {
  margin: 5px 0; /* Space between paragraphs */
  color: #adb5bd; /* Light grey for paragraphs */
  padding: 5px; /* Padding inside the border */
  margin: 5px auto; /* Centered with some margin */
  width: 80%; /* Adjust width as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* headings */

h1 {
  font-size: 2.5rem; /* Larger font size for the header */
  margin-bottom: 20px; /* Space below the header */
  text-align: center;
}

h2 {
  font-size: 2rem; /* Size for the subheader */
  margin-bottom: 20px; /* Space below the subheader */
  text-align: center;
}

h3 {
  font-size: 1.5rem; /* Size for the subheader */
  margin-bottom: 20px; /* Space below the subheader */
  text-align: center;
}

/* Landing Page */
.landing-page-container {
  text-align: center;
  padding: 50px 20px; /* Adjust padding as needed */
  color: #333; /* Adjust text color as needed */
}

.landing-page-container .logo {
  width: 150px; /* Adjust width as needed */
  margin-bottom: 30px; /* Space below the logo */
}

.landing-page-container h1 {
  font-size: 2.5rem; /* Larger font size for the header */
  margin-bottom: 20px; /* Space below the header */
}

.landing-page-container h2 {
  font-size: 2rem; /* Size for the subheader */
  margin-bottom: 20px; /* Space below the subheader */
}

.landing-page-container p {
  font-size: 1.2rem; /* Size for the paragraph */
  max-width: 600px; /* Max width for text readability */
  margin: 0 auto; /* Center text block */
}


/* Private Diary Styling */
.private-diary-container .email-signup-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  text-decoration: none; /* No underline */
  border-radius: 5px; /* Rounded borders */
  font-weight: bold; /* Make the text bold */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.private-diary-container .email-signup-link:hover {
  background-color: #367c39; /* Darker green on hover */
}

/* If you want to center the link on the page */
.private-diary-container {
  text-align: center; /* Center text and link */
}
